import React from "react";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";

/*
var pages = [
  { name: 'Plombier dépannage', href: '#', current: false },
  { name: 'Auvergne Rhône Alpes', href: '#', current: false },
  { name: 'Rhône', href: '#', current: false },
  { name: 'Albigny-sur-Saône (69250)', href: '#', current: true },
]
*/
const Ariane = ({ fil_ariane }) => {
  // Traitement du fil ariane
  var numrows = fil_ariane.length;
  var liens = [];
  for (var i = 0; i < numrows; i += 2) {
    if (i < numrows - 2) {
      liens.push({
        name: fil_ariane[i],
        href: fil_ariane[i + 1],
        current: false,
      });
    } else {
      liens.push({
        name: fil_ariane[i],
        href: fil_ariane[i + 1],
        current: true,
      });
    }
  }

  return (
    <nav
      className="hidden  max-w-7xl mx-auto pl-4 py-4 lg:block"
      aria-label="Breadcrumb"
    >
      <ol className="flex items-center">
        <li>
          <div>
            <a href="/" className="text-art-bleu hover:text-art-bleu">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Accueil</span>
            </a>
          </div>
        </li>
        {liens.map((lien) => (
          <li key={lien.name}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="flex-shrink-0 h-5 w-5 text-art-bleu"
                aria-hidden="true"
              />
              <a
                href={lien.href}
                className="text-base font-ligther text-art-bleu hover:text-art-bleu"
                aria-current={lien.current ? "page" : undefined}
              >
                {lien.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Ariane;
